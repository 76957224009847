import { HostListener, Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dynamicprint',
  templateUrl: './dynamicprint.component.html',
  styleUrls: ['./dynamicprint.component.css']
})
export class DynamicprintComponent implements OnInit {
  // someOtherData = [
  //     // {
  //     //   id: 1,
  //     //   template: '<div  *ngIf="data.message"> {{data.class}}',
  //     // },
  //     // {
  //     //   id: 2,
  //     //   template: '<div>{{data.timestampStr}}</div>',
  //     // },
  //     // {
  //     //   id: 3,
  //     //   template: '<div>{{data.message}}</div>',
  //     // },
  // ];

  someData = [];
  someOtherData: any = []
  reportData: any = []
  reportPrintData: any = []

  isloading: any = false;


  summaryBO: any = {};
  headerBO: any = {};
  printBO: any = {};
  printColl: any = [];
  printdetailsColl: any = [];
  additionalColl: any = []


  dataBO: any = null

  headerhtml: any = ""
@Input('sdata')sdata:any
  version: any = "2.1.0"


  rpturl = "https://pivot.reemerp.com";

  constructor(private sanitized: DomSanitizer) { }

  ngOnInit(): void {
    // this.fnTestdata();
    var printtype = ''
    if (this.sdata.headerBO) {
      this.headerBO = this.sdata.headerBO,
        this.printBO = this.sdata.printBO,
        this.printColl = this.sdata.printColl,
        this.printdetailsColl = this.sdata.printdetailsColl
      this.summaryBO = this.sdata.summaryBO
      this.additionalColl = this.sdata.additionalColl
    }

    var data = {
      headerBO: this.headerBO,
      data: this.headerBO,
      printBO: this.printBO,
      printColl: this.printColl,
      printdetailsColl: this.printdetailsColl,
      summaryBO: this.summaryBO,
      printtype: printtype,
      additionalColl: this.additionalColl
    }

    this.someData.push(data)


    this.headerhtml = this.sanitized.bypassSecurityTrustHtml(this.headerBO.htmlheadertxt)
    htmlcss

    var htmlcss = ""
    htmlcss = this.headerBO.htmlcss;
    if (!htmlcss) {
      htmlcss = ``
    }

    var pagesize = 1180

    if (this.headerBO.pagesize) {
      pagesize = this.headerBO.pagesize;

      if (!pagesize) {
        pagesize = 1180
      }

    }

    console.log("HTML CSS")
    console.log(htmlcss)



    var pagetype = ""
    if (this.headerBO.pagetype) {
      pagetype = this.headerBO.pagetype;
    }


    this.someOtherData.push({ id: 4, template: this.headerBO.htmlheadertxt, templatecss: [htmlcss], pagesize: pagesize, pagetype: pagetype })

  }





  @HostListener('window:message', ['$event'])
  onMessage(event) {
    console.log(event);
  }



  
}

import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';

import { AppComponent } from './app.component';
import { HelloComponent } from './hello.component';
import { CompileDirective } from './compile.directive';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ManualprintComponent } from './print/manualprint/manualprint.component';
import { RouterModule, Routes } from '@angular/router';
import {APP_BASE_HREF} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DynamicprintComponent } from './print/dynamicprint/dynamicprint.component';
const routes: Routes = [
  {
    path: '', component: AppComponent,
    children: [
      { path: 'ManualPrint', component: ManualprintComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false }),BrowserModule, FormsModule,QRCodeModule,NgxBarcodeModule,HttpClientModule],
  declarations: [ AppComponent, HelloComponent, CompileDirective, ManualprintComponent, DynamicprintComponent],
  bootstrap:    [ AppComponent ],
  schemas:[NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
  providers: [{provide: APP_BASE_HREF, useValue: '/'},
    
  ]
})
export class AppModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable(
   {
      providedIn: 'root'
   }
   
)
export class PrintService {

   constructor(private http: HttpClient) {}
       
   fnpostInputParams(url: any, params: any) {
      var headers = {
         'Access-Control-Allow-Origin': '*',
         //'Content-Type': "application/JSON",
         'authkey': (localStorage["renoerpadtoken"] === undefined) ? "1" : JSON.parse(localStorage["renoerpadtoken"])
      }
      return this.http.post('https://renowappapi.azurewebsites.net/' + url, params, { headers: headers });
   }

   getPrintInvoice(params: any) {
    return this.fnpostInputParams('report/getprintinvoice', params);
 }
}
